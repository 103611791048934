import App from "@/components/App";
import SEO from "@/components/SEO";
import { graphql, PageProps, StaticQuery } from "gatsby";
import React, { FC, Fragment } from "react";

const Home: FC<PageProps> = () => {
  return (
    <StaticQuery
      query={graphql`
        query HomePageQuery {
          contentfulHomePage {
            #     SEO
            seo {
              documentTitle
              metaTitle
              metaUrl
              metaAuthor
              description {
                description
              }
              metaKeywords
            }

            #     Banner
            banner {
              id
              bannerTitle {
                title
                sub_title
              }
              bannerContent {
                bannerContent
              }
              bannerImage {
                file {
                  url
                }
              }
            }

            #     Services
            ourServices {
              ourServices {
                id
                title
                description
                icon {
                  file {
                    url
                  }
                }
              }
            }

            #     About
            aboutOurCompany {
              title
              whatDoWeBuild
              description {
                description
              }
              statistics {
                id
                title
                subTitle
              }
            }

            # Top Features
            topFeatures {
              features {
                id
                uiPlacement
                featureTitle
                featureShortDescription
                quote
                featuresList
                featureIcon {
                  file {
                    url
                  }
                }
                featureImage {
                  file {
                    url
                  }
                }

                redirectButtons {
                  id
                  buttonName
                  buttonColor
                  path
                }
              }
            }

            #     FAQs
            popularQuestions {
              faQs {
                id
                question
                answer {
                  answer
                }
              }
            }

            #     Testimonials
            whatOurClientsSayTestimonials {
              reviews {
                id
                review {
                  review
                }
                reviewerName
                reviewerRole
                reviewerPic {
                  file {
                    url
                  }
                }
              }
            }

            #     Footer Content
            footerContent {
              content {
                raw
              }
            }

            # Structured snippet
            structuredDataSnippets {
              id
              snippet {
                snippet
              }
            }
          }
        }
      `}
      render={({
        contentfulHomePage: {
          seo,
          footerContent,
          banner,
          ourServices,
          aboutOurCompany,
          topFeatures: { features },
          popularQuestions,
          whatOurClientsSayTestimonials: { reviews },
          structuredDataSnippets,
        },
      }) => {
        const {
          documentTitle,
          metaTitle,
          metaUrl,
          description: { description },
          metaKeywords,
          metaAuthor,
        } = seo;

        return (
          <Fragment>
            <SEO
              contentfulSeo={{
                documentTitle,
                metaTitle,
                metaDescription: description,
                metaKeywords,
                metaUrl,
                metaAuthor: metaAuthor,
              }}
            />
            <App
              footerContent={footerContent}
              banner={banner}
              services={ourServices}
              aboutData={aboutOurCompany}
              features={features}
              faqs={popularQuestions?.faQs}
              reviews={reviews}
              structuredDataSnippets={structuredDataSnippets}
            />
          </Fragment>
        );
      }}
    />
  );
};

export default Home;
