import { ContentUiContext } from "@/components/Helpers/UiImageAndContentTab";
import React, { Component } from "react";

export default class Features extends Component<{ features: any }> {
  render(): JSX.Element {
    const { features } = this.props;
    return (
      <section className="section section-md bg-gray-100 ">
        {features?.map(
          ({
            id,
            uiPlacement,
            featureTitle,
            featureShortDescription,
            quote,
            featuresList,
            featureImage: {
              file: { url: featureImage },
            },
            featureIcon: {
              file: { url: featureIcon },
            },
            redirectButtons,
          }) => {
            let redirectsBtns = [];
            if (redirectButtons) {
              redirectsBtns = redirectButtons?.map(({ id, buttonName, buttonColor, path }) => {
                return {
                  path,
                  colour: buttonColor,
                  title: buttonName,
                  id,
                };
              });
            }

            return (
              <ContentUiContext
                key={id}
                title={featureTitle}
                shortDescription={featureShortDescription}
                imageURL={featureImage}
                quote={quote}
                imageDirection={
                  uiPlacement[0] === "Right Image and Left Content" ? "right" : "left"
                }
                contextURL={featureIcon}
                contentList={featuresList}
                buttons={redirectsBtns}
              />
            );
          },
        )}
      </section>
    );
  }
}
