import React, { Component, Fragment } from "react";

const TYPING_SPEED = 150;
const DELETING_SPEED = 30;

interface TypingEffectProps {
  dataText: string[];
}

export class TypingEffect extends Component<TypingEffectProps> {
  state = {
    text: "",
    isDeleting: false,
    loopNum: 0,
    typingSpeed: TYPING_SPEED,
    isUnMounted: false,
  };

  private timeIds = [];

  componentDidMount(): void {
    this.setState({
      isUnMounted: false,
    });
    this.handleType();
  }

  componentWillUnmount(): void {
    this.setState({
      isUnMounted: true,
    });

    this.timeIds.forEach((each) => {
      clearTimeout(each);
    });
  }

  handleType = (): void => {
    if (this.state.isUnMounted) {
      return;
    }

    const { dataText } = this.props;
    const { isDeleting, loopNum, text, typingSpeed } = this.state;
    const i = loopNum % dataText.length;
    const fullText = dataText[i];

    this.setState({
      text: isDeleting
        ? fullText.substring(0, text.length - 1)
        : fullText.substring(0, text.length + 1),
      typingSpeed: isDeleting ? DELETING_SPEED : TYPING_SPEED,
    });

    if (!isDeleting && text === fullText) {
      this.timeIds.push(setTimeout(() => this.setState({ isDeleting: true }), 500));
    } else if (isDeleting && text === "") {
      this.setState({
        isDeleting: false,
        loopNum: loopNum + 1,
      });
    }

    this.timeIds.push(setTimeout(this.handleType, typingSpeed));
  };

  render(): JSX.Element {
    return (
      <Fragment>
        <h3 className="offset-top-7 font-weight-regular typed-text-heading">
          <span>Build</span>
          <span className="typed-text text-primary">{this.state.text}</span>
          <span id="cursor"></span>
        </h3>
      </Fragment>
    );
  }
}
