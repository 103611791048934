import React, { FC } from "react";
import { LazyImage } from "./LazyImage";

export interface ReviewCardProps {
  reviewerName: string;
  reviewerPic: string;
  reviewerRole: string;
  review: string;
}

export const ReviewCard: FC<ReviewCardProps> = ({
  review,
  reviewerName,
  reviewerPic,
  reviewerRole,
}) => {
  return (
    <div className="col-md-6 col-lg-4 isotope-item mb-4">
      <blockquote className="quote-modern">
        <div className="quote-modern-header">
          <div className="quote-modern-info">
            <div className="quote-modern-avatar">
              <LazyImage src={reviewerPic} width={60} alt="testimonial-user" height={60} />
            </div>

            <div className="quote-modern-info-main">
              <cite className="quote-modern-cite">{reviewerName}</cite>
              <p className="quote-modern-position">{reviewerRole}</p>
            </div>
          </div>
        </div>
        <div className="quote-modern-main">
          <div className="quote-modern-text text-dark">
            <p>{review}</p>
          </div>
        </div>
      </blockquote>
    </div>
  );
};
