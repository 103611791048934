import { clipInLeftAnimation } from "@/utils/animations";
import { Link } from "gatsby";
import React, { MutableRefObject } from "react";

interface BannerContentProps {
  title: string;
  subTitle: string;
  bannerContent: string;
  visibleRef: MutableRefObject<HTMLDivElement>;
}

export const BannerContent: React.FC<BannerContentProps> = ({
  bannerContent,
  subTitle,
  title,
  visibleRef,
}) => {
  return (
    <div className="box-custom-4-main pt-5 pb-5" ref={visibleRef}>
      <div className="block-10">
        <h1
          className="font-weight-bold heading-decoration-2"
          style={clipInLeftAnimation(1, 0.1, visibleRef)}
        >
          <span className="text-white">{title}</span>
        </h1>
        {subTitle && (
          <h2
            className="extra-light text-white heading-decoration-3"
            style={clipInLeftAnimation(1, 0.1, visibleRef)}
          >
            <span>{subTitle}</span>
          </h2>
        )}
        <p className="text-white-50 big text-dark" style={clipInLeftAnimation(1, 0.1, visibleRef)}>
          {bannerContent}
        </p>
        <div className="group call-group-btns" style={clipInLeftAnimation(1, 0.1, visibleRef)}>
          <a
            className="button button-icon button-icon-right button-primary-title button-winona"
            href="tel:+91 8069647545"
            style={clipInLeftAnimation(1, 0.1, visibleRef)}
          >
            <div className="content-original">
              <span className="icon mdi mdi-cellphone-iphone" />
              Call Now
            </div>
            <div className="content-dubbed">
              <span className="icon mdi mdi-cellphone-iphone" />
              Call Now
            </div>
          </a>
          <Link
            className="button button-icon button-icon-right button-secondary button-winona"
            to="/contact-us/"
            style={clipInLeftAnimation(1, 0.1, visibleRef)}
          >
            <div className="content-original">
              <span className="icon mdi mdi-google-play" />
              Contact Us
            </div>
            <div className="content-dubbed">
              <span className="icon mdi mdi-google-play" />
              Contact Us
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
