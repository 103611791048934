import React, { PureComponent } from "react";
import Layout from "../Layout";
import { StructuredDataSnippetTag } from "../Shared/StructuredDataSnippetTag";
import AboutOurCompany, { AboutOurCompanyProps } from "./About";
import Banner, { BannerProps } from "./Banner";
import Features from "./Features";
import OurServices from "./OurServices";
import PopularQuestion from "./PopularQuestions";
import Testimonials from "./Testimonials";

interface HomePageProps extends BannerProps, AboutOurCompanyProps {
  footerContent: any;
  services: any;
  features: any;
  faqs: any;
  reviews: any;
  structuredDataSnippets: any;
}

export default class Index extends PureComponent<HomePageProps> {
  render(): JSX.Element {
    const {
      footerContent,
      banner,
      services,
      aboutData,
      features,
      faqs,
      reviews,
      structuredDataSnippets,
    } = this.props;

    return (
      <Layout contentfulFooterContentForPages={footerContent}>
        <StructuredDataSnippetTag snippets={structuredDataSnippets} />
        <Banner banner={banner} />
        <OurServices services={services} />
        <AboutOurCompany aboutData={aboutData} />
        <Features features={features} />
        <PopularQuestion faqs={faqs} />
        <Testimonials reviews={reviews} />
      </Layout>
    );
  }
}
