import { GridInfoBox } from "@/components/Helpers/GridInfoxBox";
import { TypingEffect } from "@/components/Helpers/TypingEffect";
import React, { FC } from "react";

export interface AboutOurCompanyProps {
  aboutData: {
    title: string;
    whatDoWeBuild: any;
    description: { description: string };
    statistics: any;
  };
}

const AboutOurCompany: FC<AboutOurCompanyProps> = ({ aboutData }) => {
  const {
    description: { description },
    statistics,
    title,
    whatDoWeBuild,
  } = aboutData;

  return (
    <section className="section section-box-custom-3-outer" id="about-us">
      <div className="parallax-container section-lg section-box-custom-3 section-bottom-0 text-center home-parallex-container">
        <div className="parallax-content">
          <div className="container context-dark">
            <span className="normal text-white font-weight-bold text-uppercase wow fadeIn">
              {title}
            </span>
            <TypingEffect dataText={whatDoWeBuild} />
            <p className="pl-2 pr-2">{description}</p>
          </div>
          <div className="box-custom-3-container container wow slideInUpSmall">
            <div className="box-custom-3">
              <GridInfoBox infoItems={statistics} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutOurCompany;
