import { ReviewCard } from "@/components/Helpers/ReviewCard";
import React, { Component } from "react";

export default class Testimonials extends Component<{ reviews: any }> {
  render(): JSX.Element {
    const { reviews } = this.props;
    return (
      <section className="section section-md pb-0 text-center">
        <div className="container">
          <span className="normal text-primary font-weight-bold text-uppercase wow fadeIn">
            Testimonials
          </span>
          <h3 className="offset-top-7 wow fadeIn" data-wow-delay=".1s">
            What Our Clients Say
          </h3>
          <div className="isotope-outer">
            <div className="row isotope">
              {reviews.map(
                ({
                  id,
                  review: { review },
                  reviewerName,
                  reviewerRole,
                  reviewerPic: {
                    file: { url: reviewerPic },
                  },
                }) => {
                  return (
                    <ReviewCard
                      reviewerPic={reviewerPic}
                      review={review}
                      reviewerName={reviewerName}
                      reviewerRole={reviewerRole}
                      key={id}
                    />
                  );
                },
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
