import { IndividualItemViewFromList } from "@/components/Helpers/IndividualItemViewFromList";
import React, { Component } from "react";

export default class PopularQuestion extends Component<{ faqs: any }> {
  render(): JSX.Element {
    const { faqs } = this.props;

    const list: { title: string; description: string }[] = faqs.map(
      ({ question, answer: { answer } }) => {
        return {
          title: question,
          description: answer,
        };
      },
    );

    return (
      <section className="section section-md bg-gray-100 text-center">
        <div className="container">
          <span className="normal text-primary font-weight-bold text-uppercase">
            Popular Questions
          </span>
          <h3 className="offset-top-7">Frequently Asked Questions</h3>
          <IndividualItemViewFromList lists={list} />
        </div>
      </section>
    );
  }
}
