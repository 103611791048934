/* eslint-disable camelcase */
import { PhoneFrame } from "@/components/Helpers/PhoneFrame";
import { clipInLeftAnimation } from "@/utils/animations";
import React, { FC } from "react";
import { BannerContent } from "./BannerContent";

export interface BannerProps {
  banner: {
    id: string;
    bannerTitle: {
      title: string;
      sub_title: string;
    };
    bannerContent: {
      bannerContent: string;
    };
    bannerImage: {
      file: {
        url: string;
      };
    };
  };
}

const Banner: FC<BannerProps> = ({ banner }) => {
  const visibleRef = React.createRef<HTMLDivElement>();

  const {
    bannerTitle,
    bannerContent: { bannerContent },
    bannerImage: {
      file: { url: bannerImage },
    },
  } = banner;

  return (
    <div
      className="home-banner-section row m-0 align-items-center justify-content-center"
      ref={visibleRef}
    >
      <div className="col-lg-6 col-md-3">
        <BannerContent
          visibleRef={visibleRef}
          title={bannerTitle.title}
          subTitle={bannerTitle.sub_title}
          bannerContent={bannerContent}
        />
      </div>
      <div className="col-lg-6 col-md-3 pb-2" style={clipInLeftAnimation(1, 0.3, visibleRef)}>
        <PhoneFrame imageURL={bannerImage} />
        <div className="box-custom-4-main-decoration"></div>
      </div>
    </div>
  );
};

export default Banner;
