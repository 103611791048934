import React, { FC } from "react";

interface GridInfoBoxProps {
  infoItems: { title: string; subTitle: string }[];
}

export const GridInfoBox: FC<GridInfoBoxProps> = ({ infoItems }) => {
  return (
    <div className="layout-bordered-1">
      {infoItems.map(({ title, subTitle }, index) => {
        return (
          <div className="layout-bordered-1-item" key={index}>
            <article className="counter-classic">
              <div className="counter-classic-main">
                <div className="counter">{title.replace("+", "")}</div>
                <span className="small text-primary">{title.includes("+") && "+"}</span>
              </div>
              <h4 className="counter-classic-title text-black extra-lighter">{subTitle}</h4>
            </article>
          </div>
        );
      })}
    </div>
  );
};
